import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import App from './App.js'
import React from 'react'
import { OrbitControls, Environment, Sparkles, BakeShadows, AdaptiveDpr, AdaptiveEvents } from '@react-three/drei'
import { Suspense } from 'react'


const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(

  <Canvas dpr={[1, 2]} performance={{ min: 0.5 }}> 

    <fog attach="fog" args={['#e0e4ea', 8, 22]} />
    <Environment
      intensity={1}
      near={1} 
      far={1000}
      resolution={256}
      files="env8.hdr"
      blur={0}
    />
    <BakeShadows />
    <AdaptiveDpr pixelated />
    <AdaptiveEvents />
    <Sparkles
      count={50}
      speed={0.1}
      size={4}
      opacity={0.1}
    />
    <OrbitControls
      autoRotate
      autoRotateSpeed={0.1}
      target={[0 , 1.2, 0]}
      minDistance={2}
      maxDistance={8}
      minPolarAngle={Math.PI / 5}
      maxPolarAngle={Math.PI / 2.2}
    />
   
    <Suspense fallback={null}>
    
      <App />
      
    </Suspense>
  
  </Canvas>
)
