
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Env(props) {
  const { nodes, materials } = useGLTF("/g-env.gltf");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.wall001.geometry}
        material={materials["Material.015"]}
        position={[0, -0.46766847, 0]}
        scale={12.72251225}
      />
      <mesh
        geometry={nodes.floor001.geometry}
        material={materials.gbk_floor}
        position={[-0.09470776, 0.27035439, 0.11722692]}
        rotation={[0, 1.40754139, 0]}
        scale={[1.80174208, 1.02487779, 1.80174208]}
      />
    </group>
  );
}

useGLTF.preload("/g-env.gltf");