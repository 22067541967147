import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Square(props) {
  const { nodes, materials } = useGLTF("/g-square.gltf");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.square_floor001.geometry}
        material={materials["Material.013"]}
        position={[-0.23680365, -0.3081848, 1.97456574]}
        rotation={[0, Math.PI / 2, 0]}
      />
    </group>
  );
}

useGLTF.preload("/g-square.gltf");