import { Model } from './Model.js'
import { Env } from './Env.js'
import { Square } from './Square.js'

export default function App() {

   return <>
      <Model />
      <Square />
      <Env />
  </>
}