import React, { useRef } from "react";
import { useGLTF, PerspectiveCamera } from "@react-three/drei";

export function Model(props) {
  const { nodes, materials } = useGLTF("/g-scene-2.glb");
  return (
    <group {...props} dispose={null}>
      <PerspectiveCamera
        makeDefault={true}
        far={1000}
        near={0.1}
        fov={22.89519205}
        position={[-0.04815245, 1.08831859, -5.71553373]}
        rotation={[-3.08807928, -0.00922744, -3.13757715]}
      />
      <mesh
        geometry={nodes["new-stand-lamp"].geometry}
        material={materials.Metal_GRey}
        position={[0.01035151, -0.26325837, -0.04399391]}
        scale={[0.32899055, 0.00561753, 0.32899055]}
      />
      <mesh
        geometry={nodes.head001.geometry}
        material={materials.ring}
        position={[0.43918419, 1.54768085, -0.04391811]}
        rotation={[0, 0, -0.0155295]}
        scale={0.33191931}
      />
      <mesh
        geometry={nodes.in1001.geometry}
        material={materials["Material.012"]}
        position={[0.44808888, 1.18134773, -0.07990142]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.3324458, 0.33244577, 0.33244577]}
      />
      <mesh
        geometry={nodes.in3001.geometry}
        material={materials["Material.012"]}
        position={[0.44796073, 1.18043113, -0.00649019]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.3460772, 0.34607717, 0.34607717]}
      />
      <mesh
        geometry={nodes.in4001.geometry}
        material={materials["Material.011"]}
        position={[0.45071408, 1.17237067, -0.04323205]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.33191931, 0.33191928, 0.33191928]}
      />
      <mesh
        geometry={nodes["lamp-led001"].geometry}
        material={materials["inner-light"]}
        position={[0.4508504, 1.14747119, -0.04324961]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.33191931, 0.33191928, 0.33191928]}
      />
      <mesh
        geometry={nodes.lamp_cover001.geometry}
        material={materials["Window.001"]}
        position={[0.45097834, 1.15553164, -0.04326671]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.33191931, 0.33191928, 0.33191928]}
      />
      <mesh
        geometry={nodes.body001.geometry}
        material={materials.ring}
        position={[0.43905437, 1.39131987, -0.04391811]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.33191931, 0.33191928, 0.33191928]}
      />
      <mesh
        geometry={nodes.stand002.geometry}
        material={materials.Metal_GRey}
        position={[0.20698279, 0.1819942, -0.04391811]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.33191928, 0.33191928, 0.33191931]}
      />
      <mesh
        geometry={nodes.badges001.geometry}
        material={materials.Metal_GRey}
        position={[0.44331974, 1.33595347, -0.12774463]}
        rotation={[Math.PI / 2, -3.5e-7, -1.54702143]}
        scale={0.3319194}
      >
        <mesh
          geometry={nodes.Plane001.geometry}
          material={materials.Metal_GRey}
          position={[0.03531027, -0.00040993, -0.00598939]}
        />
        <mesh
          geometry={nodes.Plane002.geometry}
          material={materials.MAT_METAL}
          position={[0.02836941, 0, -0.00173934]}
        />
      </mesh>
      <mesh
        geometry={nodes.in2001.geometry}
        material={materials["inner-light"]}
        position={[0.44977558, 1.18016338, -0.04242407]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.32231867, 0.32231858, 0.32231858]}
      />
      <mesh
        geometry={nodes["emblem-bottom002"].geometry}
        material={materials.Metal_GRey}
        position={[0.43924221, 1.59259129, -0.04104406]}
        rotation={[0.28925723, -0.46791074, -2.55832964]}
        scale={[0.24366495, 0.59925008, 0.24366494]}
      />
      <mesh
        geometry={nodes.in5001.geometry}
        material={materials["inner-light"]}
        position={[0.45008206, 1.19414055, -0.04304339]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.33191931, 0.33191928, 0.33191928]}
      />
      <mesh
        geometry={nodes.in6001.geometry}
        material={materials["Material.012"]}
        position={[0.45045054, 1.18582559, -0.04341675]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.33191931, 0.33191928, 0.33191928]}
      />
      <mesh
        geometry={nodes.in7001.geometry}
        material={materials["Material.011"]}
        position={[0.45070878, 1.14649594, -0.0433001]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.33191931, 0.33191928, 0.33191928]}
      />
      <mesh
        geometry={nodes["emblem-bottom003"].geometry}
        material={materials.Metal_GRey}
        position={[0.44064754, 1.59283257, -0.04326135]}
        rotation={[0.28925723, -0.46791074, -2.55832964]}
        scale={[0.24366495, 0.59925008, 0.24366494]}
      />
      <mesh
        geometry={nodes["inner-block001"].geometry}
        material={materials["Material.012"]}
        position={[0.45008206, 1.21187687, -0.04436144]}
        scale={0.04192529}
      />
      <mesh
        geometry={nodes["lamp-body001"].geometry}
        material={materials["Material.011"]}
        position={[0.45092773, 1.16094232, -0.04325848]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.33191931, 0.33191928, 0.33191928]}
      />
      <group
        position={[0.43901882, 1.16224921, -0.04509569]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.33191931, 0.33191928, 0.33191928]}
      >
        <mesh geometry={nodes.Plane027.geometry} material={materials.Glass} />
        <mesh
          geometry={nodes.Plane027_1.geometry}
          material={materials["little-piece"]}
        />
      </group>
      <mesh
        geometry={nodes.plate001.geometry}
        material={materials["Material.012"]}
        position={[0.43901882, 1.1643995, -0.04509569]}
        rotation={[Math.PI / 2, 0.00746174, 0]}
        scale={[0.32808053, 0.32305089, 0.32915574]}
      />
      <mesh
        geometry={nodes.ring001.geometry}
        material={materials.Metal_GRey}
        position={[0.43905437, 1.20900667, -0.04391815]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.33191931, 0.33191928, 0.33191928]}
      />
      <mesh
        geometry={nodes.tree_s1001.geometry}
        material={materials["MASE-METAL.002"]}
        position={[-1.19328225, 0.04703675, 2.73701644]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={0.43395254}
      />
      <mesh
        geometry={nodes.rock002.geometry}
        material={materials["Material.016"]}
        position={[1.93388569, -0.35283798, 3.58161116]}
        rotation={[0, 0, -1.73779303]}
        scale={[0.83307058, 1.04392707, 1.70771039]}
      />
      <mesh
        geometry={nodes["floor-middle002"].geometry}
        material={materials["Material.014"]}
        position={[0, -0.42921683, 0]}
        rotation={[0, 0.5188924, 0]}
      />
      <mesh
        geometry={nodes.logo_separated001.geometry}
        material={materials.T8}
        position={[-1.63931131, 1.65084755, 4.38162088]}
        rotation={[Math.PI / 2, -1.6e-7, Math.PI]}
        scale={0.51759481}
      />
      <mesh
        geometry={nodes.Cube003.geometry}
        material={materials["Material.019"]}
        position={[0.01204322, 0.53584874, 6.16790104]}
      />
      <mesh
        geometry={nodes.Cube004.geometry}
        material={materials["Material.018"]}
        position={[-1.94507504, 0.53420424, 4.85819626]}
      />
      <mesh
        geometry={nodes.rock003.geometry}
        material={materials["Material.017"]}
        position={[0.97217309, -1.0027734, 9.36371136]}
        rotation={[-2.06994943, 0.32589827, 1.656504]}
        scale={[3.72814298, 3.49409604, 4.48055267]}
      />
      <mesh
        geometry={nodes["floor-middle003"].geometry}
        material={materials["Material.020"]}
        position={[0, 0.53601867, 5.26577711]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.50386018}
      />
      <mesh
        geometry={nodes.Cube005.geometry}
        material={materials["Material.021"]}
        position={[0.02546138, 0.53420424, 6.02063751]}
      />
      <mesh
        geometry={nodes["plant-original_bake"].geometry}
        material={materials.Material}
        position={[2.16827941, -0.51952255, 4.23060799]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={[0.40296662, 0.40296674, 0.40296674]}
      />
    </group>
  );
}

useGLTF.preload("/g-scene-2.glb");
